import React, { useState } from 'react';
import './AddFounderPanel.css';
import { FaUser, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import axios from 'axios';
import { getBaseUrl } from './ApiContext';
import { useAuth } from './AuthContext';

function AddFounderPanel({ dealId, onClose, onFounderAdded, onCancel }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [email, setEmail] = useState('');
    // Removed companyWebsite and companyLinkedin states
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const { accessToken } = useAuth();
    const baseUrl = getBaseUrl();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        if (!linkedin && !email) {
            setError('Please provide either LinkedIn or Email.');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.post(
                `${baseUrl}/api/deals/${dealId}/add_new_founder`,
                {
                    deal_id: dealId,  // The dealId is included here
                    founder: {
                        first_name: firstName,
                        last_name: lastName,
                        linkedin: linkedin || null,
                        email: email || null,
                        company_website: null,  // Add this line
                        company_linkedin: null,  // Add this line
                    }
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.data.success) {
                onFounderAdded(response.data.person);
                onClose();
            } else {
                setError('Failed to add founder. Please try again.');
            }
        } catch (error) {
            console.error('Error adding founder:', error);
            setError('An error occurred while adding the founder. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="add-founder-panel">
            <div className="add-founder-content">
                <h2>Add New Founder</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="firstName">First Name:</label>
                        <input
                            type="text"
                            id="firstName"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <FaUser className="input-icon" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                            type="text"
                            id="lastName"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        <FaUser className="input-icon" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="linkedin">LinkedIn:</label>
                        <input
                            type="text"
                            id="linkedin"
                            value={linkedin}
                            onChange={(e) => setLinkedin(e.target.value)}
                        />
                        <FaLinkedin className="input-icon" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FaEnvelope className="input-icon" />
                    </div>
                    <div className="form-actions">
                        <button type="button" onClick={onCancel} disabled={isSubmitting}>Cancel</button>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Adding...' : 'Add Founder'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddFounderPanel;