import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaComments, FaMagic, FaPaperPlane } from 'react-icons/fa';
import { getBaseUrl } from './ApiContext';
import { useAuth } from './AuthContext';
import './LinkedinChat.css';
import { toast } from 'react-toastify';

function LinkedinChat({ founderId, accessToken, baseUrl, dealInfo, onOutreachTagUpdate }) {
    const { user } = useAuth();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        // fetchMessages();
    }, [founderId]);

    const fetchMessages = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/linkedin/messages/${founderId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            setMessages(response.data.messages || []);
        } catch (error) {
            console.error('Error fetching LinkedIn messages:', error);
        }
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;

        try {
            // Copy message to clipboard
            await navigator.clipboard.writeText(newMessage);
            toast.success('Message copied to clipboard');

            // Open founder's LinkedIn profile
            const founderLinkedIn = dealInfo?.founders?.[0]?.linkedin;
            if (founderLinkedIn) {
                let linkedInUrl = founderLinkedIn;
                if (!linkedInUrl.startsWith('http://') && !linkedInUrl.startsWith('https://')) {
                    linkedInUrl = `https://${linkedInUrl}`;
                }
                const secureLinkedInUrl = linkedInUrl.replace(/^http:/, 'https:');
                window.open(secureLinkedInUrl, '_blank');
            } else {
                toast.warn('Founder LinkedIn profile not available');
            }

            // Add the new tag
            const userName = user?.given_name || user?.name || 'Unknown User';
            const founder = dealInfo?.founders?.[0];
            if (founder && founder.id) {
                await axios.post(`${baseUrl}/api/people/${founder.id}/add_outreach_tag`, {
                    user_given_name: userName,
                    tag: `${userName} Requested LinkedIn Connection`
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                // Call onOutreachTagUpdate to update the local state in OutboundEngine
                onOutreachTagUpdate(dealInfo.id, founder.id, `${userName} Requested LinkedIn Connection`);
            } else {
                console.error('Founder information not available');
                toast.error('Could not update outreach status');
            }

            // Clear the message input
            setNewMessage('');
            toast.success('LinkedIn connection requested');
        } catch (error) {
            console.error('Error sending LinkedIn message:', error);
            toast.error('Error sending message');
        }
    };

    const handleGenerateMessage = async () => {
        setIsGenerating(true);
        const baseUrl = getBaseUrl();
        let generatedMessage = '';

        const company = dealInfo?.company || {};
        const founder = dealInfo?.founders && dealInfo.founders.length > 0 ? dealInfo.founders[0] : {};
        const outreachTags = founder.outreach_sequence_tags || [];

        const secureCompanyLinkedIn = company.linkedin ? company.linkedin.replace(/^http:/, 'https:') : 'N/A';
        const secureFounderLinkedIn = founder.linkedin ? founder.linkedin.replace(/^http:/, 'https:') : 'N/A';

        const prompt = `
        Craft a personalized LinkedIn outreach message for the following person:
        Name: ${founder.first_name || ''} ${founder.last_name || ''}
        Title: ${founder.title || 'N/A'}
        Company: ${company.name || dealInfo?.company_name || 'N/A'}
        Company Website: ${company.website || 'N/A'}
        Company LinkedIn: ${secureCompanyLinkedIn}
        Founder LinkedIn: ${secureFounderLinkedIn}
        Company Description: ${company.description || 'N/A'}
        Founder Notes: ${parseNotes(founder.notes).join(', ') || 'N/A'}
        Deal Stage: ${dealInfo?.stage || 'N/A'}
        Signal Received Date: ${dealInfo?.date_created ? new Date(dealInfo.date_created).toLocaleDateString() : 'N/A'}

        Context: You're reaching out from Kyber Knight Capital, an early-stage venture capital firm that helps companies accelerate their go-to-market and sales processes.
        The firm's LPs include CEOs and founders of Fortune 100 companies, such as:

        - John Hennessy (Chairman of Google, ex-President of Stanford)
        - Wendy White (co-head of Google X)
        - Joe White (British ambassador to the US for innovation)
        - Meg Whitman (ex-CEO of eBay & HP, board member at P&G, GM)
        - Michael Eisner (ex-CEO and Chairman of The Walt Disney Company)
        - Kevin Tsujihara (ex-CEO and Chairman of Warner Bros)
        - Gary Barber (ex-CEO and Chairman of MGM Studios)
        - Chris DeFaria (ex-President of DreamWorks Animation)
        - Toby Emmerich (producer of the Lord of the Rings and Hobbit film trilogies)
        - Mark Pincus (founder of Zynga)
        - Bernard Kim (President of Zynga)
        - David Ko (ex-COO of Zynga, early Yahoo, ex-CEO of Rally Health)
        - Ex-Chief Digital Officer of Nike
        - Owen Mahoney (CEO of Nexon)
        - Kevin Lin (co-founder of Twitch)
        - Joe Gebbia (co-founder of Airbnb)
        - Ryan Roslansky (CEO of LinkedIn)
        - Michael Vaughn (COO of Venmo)
        - David Sacks (ex-COO @ Paypal, CEO of Yammer, VC @ Craft Ventures)
        - Bill Lee (first angel investor in all of Elon Musk's companies and his best friend)
        - Vivek Sharma (global head of gaming at Facebook)
        - Kent Wakeford (co-founder of Kabam)
        - Gil Penchina (co-founder of Fastly, early eBay / PayPal)
        - Sam Shank (founder of Hotel Tonight, senior AirBnB)
        - Greg Harper (head of US for Supercell)
        - Terence Fung (Chief Strategy Officer @ Storm8)
        - Thomas Gewecke (ex-Chief Digital Officer of Warner Bros)
        - Justin Choi (founder of Cie Games)
        - Josh Resnick (co-founder of Bioware Pandemic & Sugarfina)
        - Moiz Ali (founder of Native Deodorant)
        - Steve King (co-founder of Manscaped)
        - Isabelle Parize (ex-CEO of Douglass)
        - Eros Resmini (ex-CMO of Discord)
        - Avni Yerli (CEO/founder of Crytek)
        - Daniel Kan (co-founder of Cruise Automation)
        - Jason Tan (founder of Sift Science)
        - Harpreet Rai (founder of Oura)
        - Managing partner of Towerbrook (George Soros spun out PE fund)
        - Alex Dibelius (managing partner at CVC private equity)
        - Jenny Baxter Moser (managing partner at TSG Consumer Growth Partners)
        - Jon Green (founding partner at Luxor Capital)
        - Jean Luc Allavena (senior partner at Apollo Europe)
        - Larry Berg (senior west coast partner at Apollo)
        - Mike Michaelson (former head of US private equity at KKR)
        - Ken Denman (board member at Costco, VMWare, Motorola)

        The message should:
        1. Be friendly and personalized
        2. Mention something specific about their background or company
        3. Briefly highlight Kyber Knight Capital's impressive network
        4. Ask to connect or get on a call
        5. Be hyper-personalized and extremely genuine
        6. Keep the message under 250 characters
        7. Be appropriate for a LinkedIn message (more concise than an email)
        8. Do not include special characters such as tm, ©, etc.
        9. Do not include links
        10. Address the recipient by their first name
        11. Bring up the LPs or their companies and how they might be able to help the founder, if it's relevant. Otherwise, just speak broadly about them.
        12. Always finish with a call to action to find a time to meet or learn more about the founder like "I'd love to learn about what you're building." or "Do you have 15 mins to catch up?"

        Here are some examples of good messages:
        Name - Sal here from Kyber Knight Capital, a $120mm venture fund backed by fortune 100 CEOs and founders. We love to partner with startups at the earliest stages. If you're available, I'd love to learn about what you're building. 

        Name - Sal here from Kyber Knight Capital, a $120mm venture fund backed by fortune 100 CEOs and founders. We love to partner with startups to accelerate their GTM plans. If you're available, I'd love to learn about what you're building. 

        Hey Name - I'm an investor at Kyber Knight Capital, an early stage $120mm VC fund. I'd love to learn a bit more about what you're building. Do you have 15 mins to catch up?

        Only respond with the message body.
        `;

        try {
            const response = await fetch(`${baseUrl}/api/chat`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    message: prompt,
                    chatHistory: [],
                    founder_ids: founder.id ? [founder.id] : [],
                    company_id: company.id || dealInfo?.company_id,
                    deal_id: dealInfo?.id,
                }),
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split('\n\n');

                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const content = line.slice(6).trim();
                        if (content === '[DONE]') break;
                        if (content) {
                            try {
                                const jsonData = JSON.parse(content);
                                if (jsonData.type === 'content') {
                                    generatedMessage += jsonData.content;
                                    setNewMessage(generatedMessage);
                                }
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error generating message:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    const parseNotes = (notes) => {
        if (!notes || notes === 'null' || notes === '') return [];
        try {
            const parsedNotes = JSON.parse(notes);
            return Array.isArray(parsedNotes) ? parsedNotes : [notes];
        } catch (error) {
            console.error('Error parsing notes:', error);
            return notes.split(',').map(note => note.trim());
        }
    };

    return (
        <div className="linkedin-chat">
            <div className="linkedin-chat-container">
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.sender === 'user' ? 'user-message' : 'founder-message'}`}>
                        <FaComments className="message-icon" />
                        <div className="message-content">
                            <p>{message.content}</p>
                            <span className="message-time">{new Date(message.timestamp).toLocaleString()}</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="linkedin-message-input">
                <textarea
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message..."
                />
                <div className="button-container">
                    <button
                        className="generate-button"
                        onClick={handleGenerateMessage}
                        disabled={isGenerating}
                    >
                        <FaMagic /> {isGenerating ? 'Generating...' : 'Generate Message'}
                    </button>
                    <button className="send-button" onClick={handleSendMessage}>
                        <FaPaperPlane /> Send
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LinkedinChat;